import { Injectable, isDevMode } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LS_CUSTOM_BE_API_URL } from '@core/constants/debug';

export const SHOULD_RESOLVE_URL = new HttpContextToken<boolean>(() => true);

/**
 * Resolves URL for API requests.
 *
 * ``/meta/1`` -> ``https://full.url.com/meta/1``
 *
 * To avoid this behavior use ``SHOULD_RESOLVE_URL`` token:
 *```
 * httpClient.get(URL, {
 *  context: new HttpContext().set(SHOULD_RESOLVE_URL, false)
 * }
 * ```
 *
 * It will cancel resolving for this particular request
 */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor() {
    this.checkCustomApiUrl();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(SHOULD_RESOLVE_URL) === false)
      return next.handle(request);

    return next.handle(
      request.clone({
        url: this.getUrl() + request.url,
      })
    );
  }

  private getUrl(): string {
    if (!isDevMode()) return environment.url;
    try {
      const urlFromLS = localStorage.getItem(LS_CUSTOM_BE_API_URL);
      if (!urlFromLS) return environment.url;
      return urlFromLS;
    } catch {
      return environment.url;
    }
  }

  private checkCustomApiUrl() {
    /**
     * Why? Because in iframe Chrome can cut access to local storage and cookies.
     *
     * In Chrome v115 it can be turned down with eye icon in address bar
     * ("No third-party cookies" popup)
     */
    try {
      const urlFromLS = localStorage.getItem(LS_CUSTOM_BE_API_URL);
      if (urlFromLS) {
        console.warn(
          '* * * * * * * * * *\n' +
            '* USED CUSTOM URL FOR API!!!\n*\n' +
            `* > ${urlFromLS}\n*\n` +
            '* YOU CAN CHANGE IT IN /dev-test/super-settings\n' +
            '* * * * * * * * * *'
        );
      }
    } catch {
      console.warn(
        "You don`t have access to local storage. API URL changing won't work.\n\n" +
          'Try to allow third-party cookies.'
      );
    }
  }
}
